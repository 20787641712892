import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import GenericPage from '../../components/genericPage'

import content from '../../json/2021/method.json'

const MethodologyPage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/notre-methodologie">
      <SEO 
        title="4 catégories pour mesurer l'impact positif de grandes marques - 2021"
        description="Les sites de marque françaises sont-ils à la hauteur de leurs ambitions ? A travers 4 catégories, découvrez comment Big Youth les a évalués."
        path="/2021/notre-methodologie"
        year="2021" />

      <Breadcrumb url="/2021/notre-methodologie" label="Notre méthodologie" type="method" year="2021" />

      <GenericPage slug="method" content={content} />
    </Layout>
  )
  }

export default MethodologyPage

